.container{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #3a3a3a80;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 30px;
    box-sizing: border-box;
}

.card{
    background: #fff;
    border-radius: 20px;
    width: 60%;
    min-width: 200px;
    max-width: 600px;
    height: auto;
    margin: auto;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
}

.cover{
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    min-width: 100px;
    max-width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
}

.image{
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
}

.info{
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    width: 100%;
}

.text{
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
    margin-bottom: 6px;
}

.no_image{
    box-sizing: border-box;
    background-color: #7a431c;
    width: 100%;
    height: 100%;
    font-size: 8px;
    color: #fff;
    padding: 5px;
}

.no_image .title{
    text-align: center;
    font-size: 8px;
    line-height: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
}

.no_image .author{
    text-align: right;
    font-size: 8px;
    line-height: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
}

.no_image .info{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
    border: 1px solid #fff;
    margin: unset;
    max-width: unset;
}

.line_text{
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500;
    display: block;
}


.no_image .line_text{
    text-align: right;
    font-size: 8px;
    line-height: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
}

.card_close{
    display: none;
}

@media (max-width: 768px){
    .card{
        position: relative;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .card_close{
        display: block;
        font-size: 15px;
        line-height: 100%;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .info{
        margin: 10px;
    }

    .title{
        text-align: center;
    }
    
    .author{
        text-align: center;
    }
}