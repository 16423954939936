.header{
    height: 96px;
}
.container{
    width: 100%;
    height: 96px;
    background-color: #222222;
    position: fixed;
    z-index: 2;
    transition: height 0.5s;
}
.container.firstSearch{
    height: 100vh;
    /* height: -webkit-fill-available; */
}
.form{
    width: 60%;
    height: 100%;
    min-width: 250px;
    max-width: 1000px;
    padding: 0 24px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.input{
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    margin-right: 24px;
    border-radius: 10px;
    border: 2px solid transparent;
    outline: unset;
    padding: 0 12px;
}

.input:hover,
.input:focus{
    border: 2px solid #808080;
}


.button{
    box-sizing: border-box;
    --background-color: #E5E5E5;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 24px;
    border: 2px solid transparent;
    outline: unset;
    box-sizing: border-box;
    background: center / 32px 32px no-repeat url("./images/search_icon.svg"), var(--background-color);
}

.button:hover,
.button:focus{
    border: 2px solid #808080;
}

.button:active{
    --background-color: #d1d1d1;
}