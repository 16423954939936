.container{
    margin: 24px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
}

.cover{
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    min-width: 100px;
    max-width: 100px;
    height: 150px;
}

.image{
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: contain;
}

.info{
    display: inline-block;
    box-sizing: border-box;
    margin: 0 30px;
    max-width: fit-content;
}

.title{
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
}

.author{
    text-align: left;
    margin-top: 6px;
    font-size: 18px;
    line-height: 20px;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
}

.line_text::after{
    content: ', '
}

.line_text:last-child::after{
    content: ''
}

.no_image{
    box-sizing: border-box;
    background-color: #7a431c;
    width: 100%;
    height: 100%;
    font-size: 8px;
    color: #fff;
    padding: 5px;
}

.no_image .title{
    text-align: center;
    font-size: 8px;
    line-height: 100%;
}

.no_image .author{
    text-align: right;
    font-size: 8px;
    line-height: 100%;
}

.no_image .info{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
    border: 1px solid #fff;
    margin: unset;
    max-width: unset;
}

@media (max-width: 768px){
    .container{
        margin: 24px;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .info{
        margin: 10px;
    }
    .title{
        text-align: center;
    }
    
    .author{
        text-align: center;
    }
}